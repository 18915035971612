@import '@hiredigital/ui/variables';

.container {
  position: relative;
  width: calc(100% - 40px);
  margin-left: 40px;
  border: 1px solid transparent;

  @include for-size(tablet-portrait-down) {
    display: none;
  }
  &:before {
    user-select: none;
    content: '';
    top: -201px;
    right: 0;
    position: absolute;
    width: 100%;
    height: 200px;
    background: linear-gradient(
      hsla(225, 100%, 25%, 1),
      hsla(225, 100%, 25%, 0.75) 50%,
      hsla(0, 0%, 0%, 0)
    );
    z-index: 1;

    @media (max-width: 1200px) {
      right: -20px;
    }
  }
  &:after {
    user-select: none;
    content: '';
    bottom: -200px;
    right: 0;
    position: absolute;
    width: 100%;
    height: 200px;
    background: linear-gradient(
      hsla(0, 0%, 0%, 0),
      hsla(225, 100%, 25%, 0.75) 50%,
      hsla(225, 100%, 25%, 1)
    );

    @media (max-width: 1200px) {
      right: -20px;
    }
  }

  &.companyContainer {
    &:before {
      background: linear-gradient(
        hsla(225, 91%, 9%, 1),
        hsla(225, 91%, 9%, 0.75) 50%,
        hsla(0, 0%, 0%, 0)
      );
    }

    &:after {
      bottom: -250px;
      background: linear-gradient(
        hsla(0, 0%, 0%, 0),
        hsla(225, 91%, 9%, 0.75) 50%,
        hsla(225, 91%, 9%, 1)
      );
    }
  }
}

.portfolioList {
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 540px;
  height: 958px;
  margin: -300px 0 -300px auto;

  &.talentPortfolioList {
    height: 958px;
    margin: -300px 0 -390px auto;
  }

  &.companyPortfolioList {
    height: 1100px;
    margin: -300px 0 -390px auto;
  }

  @media (max-width: 1200px) {
    margin: -300px -20px -300px auto;
  }

  @include for-size(tablet-landscape-down) {
    flex-wrap: nowrap;
    max-width: 320px;
  }
}

.spacer {
  height: 150px;
  &.smallSpacer {
    height: 50px;
  }
}

.talentPortfolioItem {
  position: relative;
  width: 250px;
  min-width: 250px;

  background-color: $primary-700;
  padding: 20px;
  box-sizing: border-box;
  margin: 10px 0 10px 20px;
  box-shadow: $shadow-20;
  border-radius: 6px;
  text-decoration: none;
  transition: transform 0.3s $cubic;

  @include for-size(tablet-landscape-down) {
    min-height: 310px;
  }

  &:before {
    pointer-events: none;
    content: '';
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 6px;
    box-shadow: $shadow-30;
    transition: all 0.3s $cubic;
    z-index: $z-background;
  }

  &:hover {
    transform: translateY(-5px);
  }

  &:hover:before {
    opacity: 1;
  }
}

.portfolioItem {
  position: relative;
  // min-height: 337px;
  width: 250px;
  min-width: 250px;

  text-align: center; // fix on ie

  background-color: $white;
  padding: 20px 15px;
  box-sizing: border-box;
  margin: 10px 0 10px 20px;
  box-shadow: $shadow-20;
  border-radius: 6px;
  text-decoration: none;
  transition: transform 0.3s $cubic;

  &:before {
    pointer-events: none;
    content: '';
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 6px;
    box-shadow: $shadow-30;
    transition: all 0.3s $cubic;
    z-index: $z-background;
  }

  &:hover {
    transform: translateY(-5px);
  }

  &:hover:before {
    opacity: 1;
  }
}

.scroll {
  display: flex;
  overflow-x: scroll;
  margin: 0 auto;
  margin-right: 20px;
  -webkit-overflow-scrolling: touch;

  @include for-size(tablet-landscape-down, 68) {
    max-width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
}

.removeScroll {
  position: absolute;
  height: 10px;
  width: 100%;
  bottom: 0px;
}

.image {
  height: 120px;
  width: 120px;
  border-radius: $border-radius;
  object-fit: cover;
}

.talentName {
  color: $white;
  // background-color: $white;
  // text-align: center;
  font-weight: 600;
  font-size: $size-5;
  line-height: $line-height-5;
  width: 100%; // fix on ie overlapping text
  word-wrap: break-word; // fix on ie overlapping text
}

.talentPrimarySkill {
  font-size: $size-3;
  line-height: $line-height-3;
  font-weight: $fw-2;
  color: $primary-100;
  margin-bottom: 16px;
  // text-align: center;
  width: 100%; // fix on ie overlapping text
  word-wrap: break-word; // fix on ie overlapping text
}

.name {
  color: $blue-120;
  background-color: $white;
  text-align: center;
  font-weight: 600;

  font-size: 20px;
  width: 100%; // fix on ie overlapping text
  word-wrap: break-word; // fix on ie overlapping text
}

.primarySkill {
  font-size: 16px;
  font-weight: 600;
  background-color: $white;
  color: $black;
  margin: 5px 0;
  margin-bottom: 10px;
  text-align: center;

  width: 100%; // fix on ie overlapping text
  word-wrap: break-word; // fix on ie overlapping text
}

.skills {
  max-height: 62px;
}

.itemContainer {
  position: relative;
}

.rightAngleLarge {
  position: absolute;
  right: -20px;
  top: 10px;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-right: 50px solid $primary-500;
  opacity: 0.5;
}
