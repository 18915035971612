@import '@hiredigital/ui/variables';

.info {
  display: flex;
  padding: 30px 0;

  @include for-size(phone-only) {
    flex-direction: column;
    padding: 0 0 32px 0;
  }
}

.itemTitle {
  font-size: $size-6;
  line-height: $line-height-6;
  font-weight: $fw-1;
  // color: $secondary-600;
  // max-width: 400px;
  @include for-size(phone-only) {
    font-size: $size-4;
    line-height: $line-height-4;
  }
}
.itemLeft {
  flex: 35%;
  // margin-right: 60px;
  @include for-size(tablet-landscape-down) {
    flex: 50%;
  }
  @include for-size(phone-only) {
    margin-right: 0;
    margin-bottom: 16px;
    // as workaround for ie11, when flex column container doesnt have definite height we will be using auto
    flex: auto;
  }
}

.itemRight {
  flex: 65%;
  margin-left: 60px;
  @include for-size(tablet-landscape-down) {
    flex: 50%;
  }
  @include for-size(phone-only) {
    margin: 0;
    // as workaround for ie11, when flex column container doesnt have definite height we will be using auto
    flex: auto;
  }
}
