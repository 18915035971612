@import '@hiredigital/ui/variables';

.heroContainer {
  max-width: $content-width + 40px;
  width: 100%;
  padding: 150px 20px 90px;
  margin: 0 auto;
  display: flex;
  color: $white;
  box-sizing: border-box;
  z-index: $z-base;
  overflow: hidden;

  @include for-size(desktop-down) {
    padding: 150px 20px 60px;
  }

  @include for-size(phone-only) {
    flex-wrap: wrap;

    padding: 100px 20px 30px;
  }
}

.rightCircleOneAlt {
  position: absolute;
  top: -70px;
  left: -130px;
  opacity: 0.5;
  border: 2px dotted $blue-80;
  border-radius: 100%;
  z-index: -1;
  width: 340px;
  height: 340px;
}

.heroTitle {
  color: $white;
  letter-spacing: -1px;
  font-size: $size-9;
  line-height: $line-height-9;
  font-weight: $fw-1;
  margin-bottom: 24px;

  @include for-size(tablet-landscape-down) {
    font-size: 40px;
    line-height: 48px;
  }

  @include for-size(phone-only) {
    font-size: $size-8;
    line-height: $line-height-8;
  }
}

.heroDescription {
  max-width: 760px;
  margin-bottom: 30px;
  font-size: $size-5;
  line-height: $line-height-5;

  @include for-size(phone-only) {
    font-size: $size-3;
    line-height: $line-height-3;
    text-align: left;
  }
}

.heroCta {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;

  @include for-size(tablet-landscape-down) {
    margin-bottom: 30px;
  }
}

.sliderEnterprise {
  padding: 40px 0px;
}
